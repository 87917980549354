import React, { useState, useEffect } from 'react';
import styles from './Header.module.scss';
import Link from 'next/link';
import Image from 'Nori/Image';
import Container from 'Nori/Container';
import MainDesktopMenu from 'Custom/Header/DesktopMenu';
import AltDesktopMenu from 'Custom/Header/AltDesktopMenu';
import { useAppContext } from 'Providers/App';
import MainMobileMenu from 'Custom/Header/MobileMenu';
import AltMobileMenu from 'Custom/Header/AltMobileMenu';
import { HEADER_CONTENT } from 'Custom/Header/Header.utils';

import BRAND_LOGO from 'Images/logos/main-logo.png';

const NavigationVariants = {
  main: MainDesktopMenu,
  alternative: AltDesktopMenu,
};

const MobileMenuVariants = {
  main: MainMobileMenu,
  alternative: AltMobileMenu,
};

const Header = ({ basic = false, content = HEADER_CONTENT }) => {
  const { mainRoute, ARIA, mobileContent } = content;
  const { mobileMenuOpen, setMobileMenuOpen } = useAppContext();
  const DesktopMenu =
    NavigationVariants[content.navigationVariant] || NavigationVariants.main;
  const MobileMenu =
    MobileMenuVariants[content.navigationVariant] || MobileMenuVariants.main;

  const handleClick = () => {
    setMobileMenuOpen(!mobileMenuOpen);
    document.body.classList.toggle('overflowHidden', !mobileMenuOpen);
  };

  useEffect(() => {
    return () => {
      document.body.classList.remove('overflowHidden');
    };
  }, []);

  return (
    <>
      <header className={styles.header} id="main-header">
        <div className={styles.desktopNavigation}>
          <DesktopMenu basic={basic} />
        </div>
        <div className={styles.mobileNavigation}>
          <Container>
            <nav aria-label={ARIA.mainNavigation}>
              <button
                className={
                  mobileMenuOpen
                    ? `${styles.buttonWrapper} ${styles.menuOpened}`
                    : styles.buttonWrapper
                }
                aria-label={ARIA.openMainNavigation}
                aria-expanded={mobileMenuOpen}
                data-design-type="clickAction"
                data-design-category="top_nav_vd"
                data-design-action="Menu"
                data-design-label="Hamburger"
                data-design-nori_id="Navigation"
                data-design-module_name="Navigation"
                data-design-module_no="00"
                onClick={handleClick}
              >
                <div className={styles.hamburgerIcon}>
                  <span className={`${styles.line} ${styles.firstLine}`} />
                  <span className={`${styles.line} ${styles.secondLine}`} />
                  <span className={`${styles.line} ${styles.thirdLine}`} />
                </div>
              </button>
              <div className="logoContainer">
                <Link href={mainRoute} className={styles.mobileNexplanonLogo}>
                  <Image
                    className={styles.mobileLogo}
                    src={BRAND_LOGO}
                    alt={ARIA.logo}
                    width={BRAND_LOGO.width}
                    height={BRAND_LOGO.height}
                    layout="intrinsic"
                    data-design-type="clickAction"
                    data-design-category="top_nav_vd"
                    data-design-action="Logo"
                    data-design-label="Nexplanon logo"
                    data-design-nori_id="Navigation"
                    data-design-module_name="Navigation"
                    data-design-module_no="00"
                  />
                </Link>
              </div>
            </nav>
          </Container>
        </div>
      </header>
      {mobileMenuOpen && <MobileMenu basic={basic} content={mobileContent} />}
    </>
  );
};

export default Header;
