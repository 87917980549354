import React from 'react';
import styles from 'Custom/ISIBar/ISIBar.module.scss';

export const ISI_INTRO = (
  <>
    <span className="firstPart">
      You should not use NEXPLANON if you are pregnant or think you may be
      pregnant; have or have had blood clots; have liver disease or a liver
      tumor; have unexplained vaginal bleeding; have breast cancer or any other
      cancer that is sensitive to progestin (a female hormone), now or in the
      past; or are allergic to anything in NEXPLANON.
    </span>{' '}
    <span>
      Talk to a trained healthcare professional about using NEXPLANON if you
      have diabetes, high cholesterol or triglycerides, headaches, gallbladder
      or kidney problems, history of depressed mood, high blood pressure,
      allergy to numbing medicines (anesthetics) or medicines used to clean your
      skin (antiseptics). These medicines will be used when the implant is
      placed into or removed from your arm.
    </span>
  </>
);

export const ISI_BAR_CONTENT = {
  ARIA: {
    openIndication: 'Open full Indication',
    openISI: 'Open full Important Safety Information',
  },
  indicationTitle: 'Indication',
  indicationIntro:
    'NEXPLANON is a prescription birth control for the prevention of pregnancy in women.',
  indicationMobile: (
    <>
      <span className={styles.highlight}>INDICATION:</span> NEXPLANON is a
      prescription birth control for the prevention of pregnancy in women.
    </>
  ),
  isiTitle: 'Important Safety Information',
  isiIntro: ISI_INTRO,
  isiMobile: (
    <>
      <span className={styles.highlight}>IMPORTANT SAFETY INFORMATION:</span>{' '}
      {ISI_INTRO}
    </>
  ),
};
