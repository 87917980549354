import React, { Children } from 'react';
import styles from 'Custom/ItemBreakpoint/index.module.scss';

const LARGER_ITEM_INDEX = 0;
const SMALLER_ITEM_INDEX = 1;

const BREAKPOINTS = {
  xs: styles.xsBreakpoint,
  sm: styles.smBreakpoint,
  md: styles.mdBreakpoint,
  lg: styles.lgBreakpoint,
  xl: styles.xlBreakpoint,
  default: styles.mdBreakpoint,
};

const ItemBreakpoint = ({ className = '', breakpoint = 'md', children }) => {
  const childrenArray = Children.toArray(children);

  const largerItem = childrenArray[LARGER_ITEM_INDEX];
  const smallerItem = childrenArray[SMALLER_ITEM_INDEX];

  const breakpointClassName = BREAKPOINTS[breakpoint] || BREAKPOINTS.default;

  return (
    <div
      className={`${styles.itemBreakpoint} ${breakpointClassName} ${className}`}
    >
      {largerItem && <div className={styles.largerItem}>{largerItem}</div>}
      {smallerItem && <div className={styles.smallerItem}>{smallerItem}</div>}
    </div>
  );
};

export default ItemBreakpoint;
