import React, { useEffect } from 'react';
import DynamicHead from 'Nori/DynamicHead';
import { useAppContext } from 'Providers/App';

const BaseLayout = ({ head = {}, hideISI = false, isiRef, children }) => {
  const { setIsISIVisible, setModalISI, setModalSNISI, setModalPaginator } =
    useAppContext();

  const scrollHandler = () => {
    if (!isiRef.current) {
      return;
    }

    const domRect = isiRef.current.getBoundingClientRect();

    const isInViewPort =
      domRect.top <=
      (window.innerHeight || document.documentElement.clientHeight);
    setIsISIVisible(isInViewPort);
  };

  useEffect(() => {
    if (!hideISI) {
      window.addEventListener('scroll', scrollHandler, true);
      scrollHandler();
    }

    const search = new URLSearchParams(window.location.search);
    if (search.get('request') === 'youtube') {
      setModalISI(true);
    }

    if (search.get('request') === 'labeling') {
      setModalSNISI(true);
      setModalPaginator(false);
    }

    return () => {
      window.removeEventListener('scroll', scrollHandler, true);
    };
  }, []);

  return (
    <>
      <DynamicHead {...head} />
      {children}
    </>
  );
};

export default BaseLayout;
