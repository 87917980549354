import React, { Fragment } from 'react';
import {
  NAVIGATION_MOBILE_LIST,
  NAVIGATION_SINGLE_ITEMS,
  NAVIGATION_ALT_LIST,
  QUICK_LIST,
} from './es/Footer.utils.js';
import Link from 'next/link';
import styles from 'Custom/Footer/Navigation.module.scss';
import Image from 'Nori/Image/index';
import Container from 'Nori/Container/index';
import ItemBreakpoint from 'Custom/ItemBreakpoint/index';
import FooterDropdown from 'Custom/FooterDropdown/index';
import FooterHeader from 'Custom/FooterDropdown/FooterHeader';
import FooterLink from 'Custom/Footer/FooterLink.js';
import RichText from 'Custom/RichText/index';
import FooterList from './FooterList';
import BRAND_LOGO from 'Images/logos/main-logo.png';

const Navigation = ({ className = '' }) => {
  return (
    <div className={`${className} ${styles.wrapper} ${styles.alternative}`}>
      <Container>
        <div className={`${styles.split} ${styles.altSplit}`}>
          <Link className={styles.logo} href="/es-us/">
            <Image
              src={BRAND_LOGO}
              width={BRAND_LOGO.width}
              height={BRAND_LOGO.height}
              layout="intrinsic"
              alt="NEXPLANON® (etonogestrel implant) 68 mg Radiopaque Logo"
              data-design-category="bottom_nav_vd"
              data-design-type="clickAction"
              data-design-action="Logo"
              data-design-label="Logotipo Nexplanon"
              data-design-nori_id="Bottom Nav"
              data-design-module_name="Bottom Nav"
              data-design-module_no="00"
            />
          </Link>
          <ItemBreakpoint
            breakpoint="lg"
            className={styles.breakpointContainer}
          >
            {/* Desktop */}
            <div className={styles.desktopBlock}>
              <FooterList
                className={styles.altFooterList}
                {...NAVIGATION_ALT_LIST}
              />
            </div>

            {/* Mobile */}
            <div className={styles.mobileBlock}>
              <div className={styles.firstBlock}>
                <FooterDropdown allowMultiple={true}>
                  {NAVIGATION_MOBILE_LIST.map((item, i) => (
                    <RichText key={`item-${i}`}>
                      <FooterHeader>{item.title}</FooterHeader>
                      <ul className={styles.listSubitems}>
                        {item.links.map((item, i) => (
                          <Fragment key={`subitem-${i}`}>
                            <li>
                              <FooterLink {...item} />
                            </li>
                          </Fragment>
                        ))}
                      </ul>
                    </RichText>
                  ))}
                </FooterDropdown>
                <ul className={styles.singleLinks}>
                  {NAVIGATION_SINGLE_ITEMS.map((item, i) => (
                    <Fragment key={`subitem-${i}`}>
                      <li>
                        <FooterLink className={styles.singleLink} {...item} />
                      </li>
                    </Fragment>
                  ))}
                </ul>
              </div>
              <div className={styles.quickLinks}>
                <FooterList className={styles.noDecoration} {...QUICK_LIST} />
              </div>
            </div>
          </ItemBreakpoint>
        </div>
      </Container>
    </div>
  );
};

export default Navigation;
