import { LOGO, SEAL, ALLY_ICON, GENERAL_JOB_CODE } from './Footer.utils.js';
import styles from './Footer.module.scss';
import Image from 'Nori/Image';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import ContainerXL from 'Nori/Container/ContainerXL';
import Container from 'Nori/Container';
import BrandFooter from 'Custom/Footer/BrandFooter';
import MainNavigation from 'Custom/Footer/Navigation';
import AltNavigation from 'Custom/Footer/AltNavigation';
import { FOOTER_CONTENT } from 'Custom/Footer/Footer.utils';

const CookieButton = dynamic(() => import('./CookieButton'), { ssr: false });

const NavigationVariants = {
  main: MainNavigation,
  alternative: AltNavigation,
};

const ContainerVariants = {
  main: ContainerXL,
  alternative: Container,
};

const Footer = ({
  basic = false,
  jobCode = GENERAL_JOB_CODE,
  content = FOOTER_CONTENT,
}) => {
  const Navigation =
    NavigationVariants[content.navigationVariant] || NavigationVariants.main;
  const FooterContainer =
    ContainerVariants[content.navigationVariant] || ContainerVariants.main;

  return (
    <footer>
      {!basic && content.showBrandFooter && <BrandFooter />}
      {!basic && <Navigation variant={content.navigationVariant} />}

      <section className={styles.secondSection}>
        <FooterContainer>
          <div className={styles.sectionWraper}>
            <div className={styles.brandLogoContainer}>
              <span className={styles.brandLogo}>
                <Image
                  src={LOGO}
                  width={215}
                  height={32}
                  alt={content.altLogoOrganon}
                />
              </span>
            </div>

            <ul className={styles.navList} role="list">
              <li>
                <Link
                  href="https://www.organon.com/privacy/"
                  target="_blank"
                  data-design-type="clickAction"
                  data-design-category="footer_ref_vd"
                  data-design-action="Link"
                  data-design-nori_id="Footer"
                  data-design-module_name="Footer"
                  data-design-module_no="00"
                  {...content.navList.privacyLink}
                />
              </li>
              <li>
                <Link
                  href="https://www.organon.com/terms-of-use/"
                  target="_blank"
                  data-design-type="clickAction"
                  data-design-category="footer_ref_vd"
                  data-design-action="Link"
                  data-design-nori_id="Footer"
                  data-design-module_name="Footer"
                  data-design-module_no="00"
                  {...content.navList.termsLink}
                />
              </li>
              <li>
                <CookieButton {...content.navList.cookiesButton} />
              </li>
              <li>
                <Link
                  href="/site-map/"
                  data-design-category="footer_ref_vd"
                  data-design-type="clickAction"
                  data-design-action="Link"
                  data-design-nori_id="Footer"
                  data-design-module_name="Footer"
                  data-design-module_no="00"
                  {...content.navList.siteMapLink}
                />
              </li>
            </ul>
          </div>
          <div className={styles.copySection}>
            <div className={styles.copyright}>
              <p>
                {`© ${new Date().getFullYear()} ${content.copyright} `}
                <span className="job-code">{jobCode}</span>
              </p>
            </div>
            <div className={styles.logoSection}>
              <Link
                href="https://privacy.truste.com/privacy-seal/validation?rid=1ee67517-0029-4dd0-86b0-1262f41e225e"
                target="_blank"
                className={styles.sealIcon}
              >
                <Image
                  src={SEAL}
                  alt="TRUSTe"
                  width={143}
                  height={47}
                  data-design-type="clickAction"
                  data-design-category="footer_ref_vd"
                  data-design-action="Icon"
                  data-design-label="TRUSTe"
                  data-design-nori_id="Footer"
                  data-design-module_name="Footer"
                  data-design-module_no="00"
                />
              </Link>
              <Link
                href="https://www.levelaccess.com/organon/"
                target="_blank"
                className={styles.allyIcon}
              >
                <Image
                  src={ALLY_ICON}
                  width={119}
                  height={47}
                  alt="Accessibility icon"
                  data-design-type="clickAction"
                  data-design-category="footer_ref_vd"
                  data-design-action="Icon"
                  data-design-label="Accessibility icon"
                  data-design-nori_id="Footer"
                  data-design-module_name="Footer"
                  data-design-module_no="00"
                />
              </Link>
            </div>
          </div>
        </FooterContainer>
      </section>
    </footer>
  );
};

export default Footer;
