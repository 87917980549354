import { useEffect } from 'react';
import getFocusableElements from 'Utils/functions/getFocusableElements';

const NO_ELEMENTS = 0;
const FIRST_ELEMENT_INDEX = 0;
const LAST_ELEMENT_OFFSET = 1;

const useTrapFocus = (containerRef, enable = true, dependencies = []) => {
  useEffect(() => {
    if (!containerRef.current || !enable) return;
    const container = containerRef.current;
    const focusableElements = getFocusableElements(container);

    if (focusableElements.length === NO_ELEMENTS) return;

    const firstElement = focusableElements[FIRST_ELEMENT_INDEX];
    const lastElement =
      focusableElements[focusableElements.length - LAST_ELEMENT_OFFSET];

    const handleKeydown = (e) => {
      if (e.key === 'Tab' && !e.shiftKey && e.target === lastElement) {
        e.preventDefault();
        firstElement.focus();
      } else if (e.key === 'Tab' && e.shiftKey && e.target === firstElement) {
        e.preventDefault();
        lastElement.focus();
      }
    };

    document.addEventListener('keydown', handleKeydown);

    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [containerRef, enable, ...dependencies]);
};

export default useTrapFocus;
