import Router from 'next/router';

const ENV = process.env.NEXT_PUBLIC_ENV;
const FINDER_SCRIPT_PRODUCTION =
  process.env.NEXT_PUBLIC_FINDER_SCRIPT_PRODUCTION;
const FINDER_SCRIPT_STAGE = process.env.NEXT_PUBLIC_FINDER_SCRIPT_STAGE;

export const FINDER_SCRIPT =
  ENV === 'production' ? FINDER_SCRIPT_PRODUCTION : FINDER_SCRIPT_STAGE;

export const FINDER = {
  ANCHORS: ['#doctortemp', '#find-a-doctor'],
  WIDGET_WRAPPER_ID: 'find-a-doctor-wrapper',
  CHECK_POSITION_DELAY: 300,
  INITIAL_POSITION: 0,
  WIDGET_OFFSET: 40,
  SELECTORS: {
    HEADER: '#main-header',
    ISI_FILLER: '#isi-filler',
    DIRECTORY_APP: '#directory-app',
    WIDGET: '#find-a-doctor',
  },
};

export const finderHandlerClick = (event) => {
  const iframe = document.getElementById(FINDER.WIDGET_WRAPPER_ID);

  if (!iframe) {
    event.preventDefault();
    Router.push('/' + FINDER.SELECTORS.WIDGET);
  }
};

export default FINDER;
