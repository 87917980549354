import styles from './FooterList.module.scss';
import FooterLink from 'Custom/Footer/FooterLink.js';

const FooterList = ({
  title = null,
  links = [],
  designCategory = 'bottom_nav_vd',
  designNoriId = 'Bottom Nav',
  designModuleName = 'Bottom Nav',
  designModuleNumber = '00',
  className = '',
}) => {
  return (
    <ul className={`${styles.list} ${className}`}>
      {title && <li className={styles.title}>{title}</li>}
      {links &&
        links.map((item, i) => (
          <li key={`subitem-${i}`}>
            <FooterLink
              designCategory={designCategory}
              designNoriId={designNoriId}
              designModuleName={designModuleName}
              designModuleNumber={designModuleNumber}
              {...item}
            />
          </li>
        ))}
    </ul>
  );
};

export default FooterList;
