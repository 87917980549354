export const ARIA = {
  mainNavigation: 'Navegación principal',
  openMainNavigation: 'Abrir navegación principal',
  logo: 'NEXPLANON® (etonogestrel implant) 68 mg Radiopaque Logo',
};

export const Links = [
  {
    href: '/es-us/efectos-secundarios',
    label: 'Efectos Secundarios y Riesgos',
    id: 1,
  },
  {
    href: '/es-us/preguntas',
    label: 'Preguntas Frecuentes',
    id: 2,
  },
];

export const STICKY_CONTENT = {
  variant: 'alternative',
  navList: {
    findDoctor: {
      children: 'Buscar un Doctor',
      'data-design-label': 'Buscar un Doctor',
      target: '_blank',
      href: '/what-is-nexplanon/#find-a-doctor',
      'data-custom-redirect': false,
    },
    prescribingInfo: {
      children: 'Información de Prescripción',
      href: '/static/pdf/PI.pdf',
      'data-design-label': 'Información de Prescripción',
    },
    patientInfo: {
      children: 'Información para el Paciente',
      href: '/static/pdf/PPI.pdf',
      'data-design-label': 'Información para el Paciente',
    },
    importantSafety: {
      children: 'Información de Seguridad Importante',
      'data-design-label': 'Información de Seguridad Importante',
    },
    healthcare: {
      children: 'Profesional de la Salud',
      'data-design-label': 'Profesional de la Salud',
    },
    lang: {
      href: '/',
      children: 'Inglés',
      'data-design-label': 'Inglés',
    },
  },
};

export const MOBILE_MENU_CONTENT = {
  mainNavList: Links,
  secondaryNavList: STICKY_CONTENT.navList,
};

export const HEADER_CONTENT = {
  navigationVariant: 'alternative',
  mainRoute: '/es-us/',
  links: Links,
  mobileContent: MOBILE_MENU_CONTENT,
  ARIA,
};
