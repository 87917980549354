import React, { useState, Children, useEffect } from 'react';
import { useRouter } from 'next/router';
import styles from 'Nori/Accordion/index.module.scss';

const HEADER_ELEMENT = 0;
const CONTENT_ELEMENT = 1;

const Accordion = ({
  className = '',
  allowMultiple = false,
  defaultExpanded = false,
  defaultIndex = null,
  children,
}) => {
  const length = children.length;
  const router = useRouter();
  const closedState = Object.assign({}, Array(length).fill(false));
  const defaultState = Object.assign({}, Array(length).fill(defaultExpanded));
  const [itemStates, setItemStates] = useState(
    Number.isInteger(defaultIndex)
      ? { ...defaultState, [defaultIndex]: true }
      : defaultState
  );

  const handleClick = (index, isExpanded) => {
    if (allowMultiple) {
      setItemStates((prevState) => ({ ...prevState, [index]: !isExpanded }));
    } else {
      setItemStates({ ...closedState, [index]: !isExpanded });
    }
  };

  useEffect(() => {
    const handleHashChange = () => {
      const currentHash = window.location.hash.replace('#', '');
      if (!currentHash) {
        return;
      }

      const index = Children.toArray(children).findIndex(
        (item) => item.props.id === currentHash
      );

      setItemStates((prevState) => ({ ...prevState, [index]: true }));
    };

    handleHashChange();
    window.addEventListener('hashchange', handleHashChange);
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  const items = Children.map(children, (child, index) => {
    const itemId = child.props.id;
    const isExpanded = itemStates[index];
    const accordionHeader = child.props.children[HEADER_ELEMENT];
    const accordionContent = child.props.children.slice(CONTENT_ELEMENT);

    return (
      <div className={styles.item} id={itemId}>
        <div
          className={styles.header}
          onClick={() => handleClick(index, isExpanded)}
        >
          {React.cloneElement(accordionHeader, { isExpanded })}
        </div>
        <div
          className={`${styles.accordionContent} ${
            isExpanded ? styles.expanded : styles.collapsed
          }`}
        >
          {accordionContent}
        </div>
      </div>
    );
  });

  return <div className={className}>{items}</div>;
};

export default Accordion;
