import { ISI_BAR_CONTENT } from 'Custom/ISIBar/ISIBar.utils';
import { ISI_CONTENT } from 'Custom/ISI/ISI.utils';

export const ISI_MODAL_CONTENT = {
  moduleName: 'Indication and Important Safety Information',
  ARIA: {
    closeModal: 'Cerrar Modal',
  },
  indicationTitle: 'Indication',
  isiTitle: 'Important Safety Information',
  indicationContent: ISI_BAR_CONTENT.indicationIntro,
  content: ISI_CONTENT,
};
