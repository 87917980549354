import React from 'react';
import styles from 'Custom/RichText/index.module.scss';

const RichText = ({ className = '', children, ...props }) => (
  <div className={`${styles.richText} ${className}`} {...props}>
    {children}
  </div>
);

export default RichText;
