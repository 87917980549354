import React from 'react';
import NextImage from 'next/image';

const loader = ({ src }) => src;

const Image = (props) => {
  const { fill, width, height, ...rest } = props;

  const dimensions = !fill
    ? {
        width,
        height,
      }
    : {};

  return (
    <NextImage
      loader={loader}
      fill={fill}
      unoptimized
      {...dimensions}
      {...rest}
    />
  );
};

Image.defaultProps = {
  src: '',
  alt: '',
  fill: false,
  width: '100%',
  height: '100%',
};

export default Image;
