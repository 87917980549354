import React from 'react';

const SCHEMAS = {
  '/': (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/","@type":"MedicalWebPage","name":"MEET NEXPLANON","description":"the birth control implant that goes in your arm"}`,
        }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/","@type":"MedicalAudience","audienceType":"Patient","geographicArea":"This site is intended only for residents of the United States, its territories, and Puerto Rico."}`,
        }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/","@type":"WebSite","name":"Nexplanon.com"}`,
        }}
      />
    </>
  ),
  'what-is-nexplanon': (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/what-is-nexplanon/","@type":"MedicalWebPage","name":"WHAT IS NEXPLANON?","description":"NEXPLANON is a birth control implant that’s placed just under the skin of the inner, non-dominant upper arm."}`,
        }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/what-is-nexplanon/","@type":"MedicalAudience","audienceType":"Patient","geographicArea":"This site is intended only for residents of the United States, its territories, and Puerto Rico."}`,
        }}
      />
    </>
  ),
  'how-does-nexplanon-work': (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/how-does-nexplanon-work/","@type":"MedicalWebPage","name":"HOW NEXPLANON WORKS","description":"Once it gets inserted, it releases a steady, low dose of progestin, a hormone that works to prevent pregnancy in 3 ways"}`,
        }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/how-does-nexplanon-work/","@type":"MedicalAudience","audienceType":"Patient","geographicArea":"This site is intended only for residents of the United States, its territories, and Puerto Rico."}`,
        }}
      />
    </>
  ),
  'birth-control-options': (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/birth-control-options/","@type":"MedicalWebPage","name":"I’m currently on NEXPLANON, but what are my other options?","description":"Talk with your doctor about the birth control options that are right for you"}`,
        }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/birth-control-options/","@type":"MedicalAudience","audienceType":"Patient","geographicArea":"This site is intended only for residents of the United States, its territories, and Puerto Rico."}`,
        }}
      />
    </>
  ),
  insertion: (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/insertion/","@type":"MedicalWebPage","name":"where is NEXPLANON inserted?","description":"A trained healthcare professional will insert the NEXPLANON implant on the inside of your upper, non-dominant arm"}`,
        }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/insertion/","@type":"MedicalWebPage","name":"insertion aftercare","description":"While the insertion site is healing, keep the area covered with a smaller bandage."}`,
        }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/insertion/","@type":"MedicalAudience","audienceType":"Patient","geographicArea":"This site is intended only for residents of the United States, its territories, and Puerto Rico."}`,
        }}
      />
    </>
  ),
  removal: (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/removal/","@type":"MedicalWebPage","name":"getting NEXPLANON removed","description":"Removal of NEXPLANON involves a minor surgical procedure by a trained healthcare professional and can be performed in the office through a small incision in your arm where NEXPLANON is located."}`,
        }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/removal/","@type":"MedicalWebPage","name":"healing from the removal procedure","description":"While the insertion site is healing, keep the area covered with a smaller bandage."}`,
        }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/removal/","@type":"MedicalAudience","audienceType":"Patient","geographicArea":"This site is intended only for residents of the United States, its territories, and Puerto Rico."}`,
        }}
      />
    </>
  ),
  'replacing-implant': (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/replacing-implant/","@type":"MedicalWebPage","name":"REPLACING YOUR IMPLANT","description":"A trained healthcare professional can remove NEXPLANON at any time during the 3-year period. If you want to keep it for 3 years, remember to remove it by the date shown on your user card for NEXPLANON"}`,
        }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/replacing-implant/","@type":"MedicalAudience","audienceType":"Patient","geographicArea":"This site is intended only for residents of the United States, its territories, and Puerto Rico."}`,
        }}
      />
    </>
  ),
  'pregnancy-after-nexplanon': (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/pregnancy-after-nexplanon/","@type":"MedicalWebPage","name":"GETTING PREGNANT AFTER NEXPLANON","description":"NEXPLANON is reversible and can be removed by a trained healthcare professional at any time. Once you’ve made the decision, you could become pregnant as early as a week after the removal of NEXPLANON."}`,
        }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/pregnancy-after-nexplanon/","@type":"MedicalAudience","audienceType":"Patient","geographicArea":"This site is intended only for residents of the United States, its territories, and Puerto Rico."}`,
        }}
      />
    </>
  ),
  effectiveness: (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/effectiveness/","@type":"MedicalWebPage","name":"How Effective is NEXPLANON?","description":"NEXPLANON is over 99%* effective in preventing pregnancies"}`,
        }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/effectiveness/","@type":"MedicalAudience","audienceType":"Patient","geographicArea":"This site is intended only for residents of the United States, its territories, and Puerto Rico."}`,
        }}
      />
    </>
  ),
  'side-effects': (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/side-effects/","@type":"MedicalWebPage","name":"what are the most common side effects of NEXPLANON?","description":"The most common side effect of NEXPLANON is a change in your normal menstrual bleeding pattern."}`,
        }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/side-effects/","@type":"MedicalAudience","audienceType":"Patient","geographicArea":"This site is intended only for residents of the United States, its territories, and Puerto Rico."}`,
        }}
      />
    </>
  ),
  'period-changes': (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/period-changes/","@type":"MedicalWebPage","name":"how does your period change?","description":"You may experience longer or shorter bleeding during your periods or have no bleeding at all. The time between periods may vary, and in between periods you may also have spotting."}`,
        }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/period-changes/","@type":"MedicalAudience","audienceType":"Patient","geographicArea":"This site is intended only for residents of the United States, its territories, and Puerto Rico."}`,
        }}
      />
    </>
  ),
  'calling-your-doctor': (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/calling-your-doctor/","@type":"MedicalWebPage","name":"if you are experiencing any of these symptoms, contact your doctor immediately","description":"pain in your lower leg that does not go away; severe chest pain or heaviness in the chest; sudden shortness of breath, sharp chest pain, or coughing blood; symptoms of a severe allergic reaction, such as swollen face, tongue or throat, trouble breathing or swallowing; sudden severe headaches unlike your usual headaches; weakness or numbness in your arm, leg, or trouble speaking; sudden partial or complete blindness; yellowing of your skin or whites of your eyes, especially with fever, tiredness, loss of appetite, dark-colored urine, or light-colored bowel movements; severe pain, swelling, or tenderness in the lower stomach (abdomen); lump in your breast; problems sleeping, lack of energy, tiredness, or you feel very sad; heavy menstrual bleeding; or if you feel that the implant may have broken or bent while in your arm."}`,
        }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/calling-your-doctor/","@type":"MedicalAudience","audienceType":"Patient","geographicArea":"This site is intended only for residents of the United States, its territories, and Puerto Rico."}`,
        }}
      />
    </>
  ),
  'medicine-interactions': (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/medicine-interactions/","@type":"MedicalWebPage","name":"MEDICINE INTERACTIONS WITH NEXPLANON","description":"Be sure to inform your doctor about prescription medicines, non-prescription medicines, vitamins, and/or herbal products you take."}`,
        }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/medicine-interactions/","@type":"MedicalAudience","audienceType":"Patient","geographicArea":"This site is intended only for residents of the United States, its territories, and Puerto Rico."}`,
        }}
      />
    </>
  ),
  cost: (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/cost/","@type":"MedicalWebPage","name":"can you get NEXPLANON for free?","description":"You may be able to get NEXPLANON for free, as a result of the Affordable Care Act"}`,
        }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/cost/","@type":"MedicalAudience","audienceType":"Patient","geographicArea":"This site is intended only for residents of the United States, its territories, and Puerto Rico."}`,
        }}
      />
    </>
  ),
  'insurance-coverage': (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/insurance-coverage/","@type":"MedicalWebPage","name":"is NEXPLANON covered by your insurance?","description":"NEXPLANON may be covered as a medical benefit or as a pharmacy benefit"}`,
        }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/insurance-coverage/","@type":"MedicalAudience","audienceType":"Patient","geographicArea":"This site is intended only for residents of the United States, its territories, and Puerto Rico."}`,
        }}
      />
    </>
  ),
  questions: (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/questions/","@type":"FAQPage","mainEntity":[{"@type":"Question","name":"is NEXPLANON a long-acting birth control?","acceptedAnswer":{"@type":"Answer","text":"Yes, NEXPLANON is a small (about the size of a matchstick), thin, and flexible birth control implant that provides 3 years* of continuous pregnancy prevention"}},{"@type":"Question","name":"is NEXPLANON an IUD?","acceptedAnswer":{"@type":"Answer","text":"No, it's not an intrauterine device (IUD). It is an implant that is placed in your arm. Like an IUD, it's a long-acting birth control option. NEXPLANON lasts for 3 years"}},{"@type":"Question","name":"how big is NEXPLANON?","acceptedAnswer":{"@type":"Answer","text":"NEXPLANON is 1.6 inches (4 centimeters) in length, about the size of a matchstick."}},{"@type":"Question","name":"does NEXPLANON contain estrogen?","acceptedAnswer":{"@type":"Answer","text":"No. NEXPLANON does not contain estrogen."}},{"@type":"Question","name":"does NEXPLANON work as well as the pill?","acceptedAnswer":{"@type":"Answer","text":"NEXPLANON is over 99% effective (less than 1 pregnancy per 100 women who used NEXPLANON for 1 year) at preventing pregnancy"}},{"@type":"Question","name":"what if I change my mind and want to stop using NEXPLANON before 3 years?","acceptedAnswer":{"@type":"Answer","text":"A trained healthcare professional can remove the implant at any time within the 3-year period."}},{"@type":"Question","name":"can I use NEXPLANON when I am breastfeeding?","acceptedAnswer":{"@type":"Answer","text":"If you’re breastfeeding your child, you may use NEXPLANON if 4 weeks have passed since you had your baby"}}]}`,
        }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/questions/","@type":"MedicalAudience","audienceType":"Patient","geographicArea":"This site is intended only for residents of the United States, its territories, and Puerto Rico."}`,
        }}
      />
    </>
  ),
  'what-to-expect': (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/what-to-expect/","@type":"MedicalWebPage","name":"what to expect after insertion","description":"Once the implant is placed, you and your healthcare professional should check that it is in your arm by feeling for it. If you cannot feel the implant immediately after insertion, the implant may not have been inserted, or it may have been inserted deeply"}`,
        }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/what-to-expect/","@type":"MedicalAudience","audienceType":"Patient","geographicArea":"This site is intended only for residents of the United States, its territories, and Puerto Rico."}`,
        }}
      />
    </>
  ),
  'five-fast-facts': (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/five-fast-facts/","@type":"MedicalWebPage","name":"GET TO KNOW NEXPLANON","description":"with our five fast facts"}`,
        }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"http://schema.org/","url":"https://www.nexplanon.com/five-fast-facts/","@type":"MedicalAudience","audienceType":"Patient","geographicArea":"This site is intended only for residents of the United States, its territories, and Puerto Rico."}`,
        }}
      />
    </>
  ),
};

export default SCHEMAS;
