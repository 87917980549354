import styles from './FooterLink.module.scss';
import Link from 'next/link';
import ArrowRightIcon from 'Images/brand-icons/arrow-right.svg';
import DownloadFileIcon from 'Images/brand-icons/download-file.svg';
import { useAppContext } from 'Providers/App';

const ICONS = {
  file: DownloadFileIcon,
  arrow: ArrowRightIcon,
};

const FooterLink = ({
  href = null,
  label,
  isSubItem = false,
  target = null,
  modal = false,
  className = '',
  designCategory = 'bottom_nav_vd',
  designNoriId = 'Bottom Nav',
  designModuleName = 'Bottom Nav',
  designModuleNumber = '00',
  pdf = false,
  itemIcon = null,
}) => {
  let icon = null;

  const { handleModalClick, openedModal } = useAppContext();

  if (target === '_blank') {
    icon = <ArrowRightIcon />;
  }

  const AltIcon = ICONS[itemIcon];

  return href ? (
    <Link className={`${styles.link} ${className}`} href={href} target={target}>
      <span
        data-design-category={designCategory}
        data-design-type="clickAction"
        data-design-action={pdf ? 'Download' : 'Link'}
        data-design-label={label}
        data-design-nori_id={designNoriId}
        data-design-module_name={designModuleName}
        data-design-module_no={designModuleNumber}
      >
        {label}
        {AltIcon ? <AltIcon /> : icon}
      </span>
    </Link>
  ) : modal ? (
    <button
      data-design-category={designCategory}
      data-design-type="clickAction"
      data-design-action="Expand"
      data-design-label={label}
      data-design-nori_id={designNoriId}
      data-design-module_name={designModuleName}
      data-design-module_no={designModuleNumber}
      className={`${styles.link} ${className}`}
      onClick={handleModalClick}
      aria-expanded={openedModal}
    >
      {label}
      {AltIcon ? <AltIcon /> : icon}
    </button>
  ) : (
    <span
      data-design-category={designCategory}
      data-design-type="clickAction"
      data-design-action="Link"
      data-design-label={label}
      data-design-nori_id={designNoriId}
      data-design-module_name={designModuleName}
      data-design-module_no={designModuleNo}
      className={`${styles.link} ${className}`}
    >
      {label}
      {icon}
    </span>
  );
};

export default FooterLink;
