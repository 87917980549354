import React from 'react';
import Button from 'Nori/Button';
import styles from 'Nori/Button/ButtonCaption.module.scss';

const ButtonCaption = ({
  className = '',
  caption = '',
  breakCaptionOnMobile = false,
  ...props
}) => {
  const breakClassName = breakCaptionOnMobile ? styles.breakOnMobile : '';

  return (
    <div className={`${styles.buttonCaption} ${breakClassName} ${className}`}>
      <Button className={`${styles.buttonItem}`} {...props} />
      {caption && <span className={styles.captionItem}>{caption}</span>}
    </div>
  );
};

export default ButtonCaption;
