import React from 'react';
import styles from 'Custom/Footer/BrandFooter.module.scss';
import ContainerXL from 'Nori/Container/ContainerXL';
import RichText from 'Custom/RichText';
import Button from 'Nori/Button';
import ButtonStyles from 'Nori/Button/index.module.scss';

const BLOCK_BACKGROUND = {
  secondary2Light: styles.secondary2Light,
  secondary4Light: styles.secondary4Light,
  default: styles.secondary2Light,
};

const BUTTON_VARIANT = {
  secondary2Light: 'secondary2',
  secondary4Light: 'secondary4',
  default: 'secondary2',
};

const BUTTON_SIZES = {
  small: ButtonStyles.small,
};

const BannerCTA = ({
  className = '',
  color = 'secondary2Light',
  button = {
    href: '/enrollment/',
    label: 'Register My NEXPLANON',
    target: '_self',
    size: 'small',
  },
  size = 'small',
  moduleName,
  moduleNo,
  children,
  ...props
}) => {
  const backgroundClassName =
    BLOCK_BACKGROUND[color] || BLOCK_BACKGROUND.default;
  const buttonVariant = BUTTON_VARIANT[color] || BUTTON_VARIANT.default;
  const sizeClassName = BUTTON_SIZES[size] || BUTTON_SIZES.default;

  return (
    <div
      className={`${styles.wrapper} ${backgroundClassName} ${className}`}
      {...props}
      data-design-visible="visible_vd"
      data-design-type="module"
      data-design-nori_id="Bottom Nav"
      data-design-module_name="Bottom Nav"
      data-design-module_no="00"
    >
      <ContainerXL className={styles.containerWrap}>
        <RichText className={styles.mainContainer}>
          <div className={styles.copy}>
            <p className="h3">Already on NEXPLANON?</p>
            <p className={styles.body}>
              Receive tips to help you with your journey on NEXPLANON.
            </p>
          </div>

          <Button
            className={sizeClassName}
            type={buttonVariant}
            href={button.href}
            data-design-type="clickAction"
            data-design-category="button_vd"
            data-design-action="Link"
            data-design-label={button.label}
            data-design-nori_id="Bottom Nav"
            data-design-module_name="Bottom Nav"
            data-design-module_no="00"
          >
            {button.label}
          </Button>
        </RichText>
      </ContainerXL>
    </div>
  );
};

export default BannerCTA;
