import React from 'react';
import ModalSmall from 'Nori/Modal/ModalSmall';
import Link from 'next/link';
import CloseIcon from '@/public/assets/images/brand-icons/close-modal-nexplanon.svg';
import { HEALTHCARE_MODAL_CONTENT } from 'Custom/Modal/HealthcareModal.utils';
import { useAppContext } from 'Providers/App';

import styles from 'Custom/Modal/HealthcareModal.module.scss';

const HealthcareModal = ({
  className = '',
  content = HEALTHCARE_MODAL_CONTENT,
  ...props
}) => {
  const { setOpenedModal, openedModal } = useAppContext();
  const { ARIA, title, children, returnButton, confirmButton, bottomContent } =
    content;

  const handleClose = () => {
    setOpenedModal(false);
  };

  return (
    <ModalSmall
      className={`${styles.healthcareModal} ${className}`}
      isOpen={openedModal}
      onClose={handleClose}
      data-design-visible="visible_vd"
      data-design-type="module"
      data-design-nori_id="Modal"
      data-design-module_name={title}
      data-design-module_no="00"
      {...props}
    >
      <button
        aria-label={ARIA.closeModal}
        className={styles.closeIcon}
        onClick={handleClose}
      >
        <CloseIcon
          data-design-category="button_vd"
          data-design-type="clickAction"
          data-design-action="Close"
          data-design-label="close icon"
          data-design-nori_id="Modal"
          data-design-module_name={title}
          data-design-module_no="00"
        />
      </button>
      <div className={styles.modalContent}>
        <h4>{title}</h4>
        {children}
        <p>
          <button
            onClick={handleClose}
            data-design-category="content_link_vd"
            data-design-type="clickAction"
            data-design-action="Close"
            data-design-nori_id="Modal"
            data-design-module_name={title}
            data-design-module_no="00"
            className={styles.noButton}
            {...returnButton}
          />
        </p>
        <p>
          <Link
            onClick={handleClose}
            target="_blank"
            data-design-category="content_link_vd"
            data-design-type="clickAction"
            data-design-action="Link"
            data-design-nori_id="Modal"
            data-design-module_name={title}
            data-design-module_no="00"
            {...confirmButton}
          />
          {confirmButton.caption && (
            <span className={styles.linkCaption}>{confirmButton.caption}</span>
          )}
        </p>
        {bottomContent}
      </div>
    </ModalSmall>
  );
};

export default HealthcareModal;
