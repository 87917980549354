import React from 'react';
import Link from 'next/link';
import styles from 'Nori/Button/index.module.scss';

 // make sure that the SVG "fill" property is set to "clear" for the hover to work properly in primaryLight2
import MAGNIFYING_GLASS from 'Images/brand-icons/magnifying-glass.svg';
import PERSON from 'Images/brand-icons/person.svg';

const BUTTON_TYPES = {
  primary: styles.primary,
  secondary: styles.secondary,
  secondary2: styles.secondary2,
  secondary4: styles.secondary4,
  outlineSecondary: styles.outlineSecondary,
  outlineSecondary2: styles.outlineSecondary2,
  primaryLight: styles.primaryLight,
  primaryLight2: styles.primaryLight2,
  secondaryLight: styles.secondaryLight,
  simple: styles.simple,
  default: styles.primary,
};

const BUTTON_SIZES = {
  large: styles.large,
  medium: styles.medium,
  small: styles.small,
  default: styles.large,
};

const ICON_TYPES = {
  person: PERSON,
  magnifyingGlass: MAGNIFYING_GLASS,
}

const Icon = ({ name, ...props }) => {
  let Icon = ICON_TYPES[name];
  return <Icon {...props} />;
};

const Button = ({
  className = '',
  type = 'primary',
  size = 'large',
  href = null,
  icon = null,
  children,
  ...props
}) => {
  const typeClassName = BUTTON_TYPES[type] || BUTTON_TYPES.default;
  const sizeClassName = BUTTON_SIZES[size] || BUTTON_SIZES.default;

  const buttonProps = {
    ...props,
    className: `${styles.button} ${typeClassName} ${sizeClassName} ${className}`,
  };

  if (href !== null) {
    return (
      <Link data-design-category="button_vd" href={href} {...buttonProps}>
        {icon && <Icon name={icon} /> }
        {children}
      </Link>
    );
  }

  return (
    <button data-design-category="button_vd" {...buttonProps}>
      {children}
    </button>
  );
};

export default Button;
