import React from 'react';

export const LOGO = '/assets/images/logos/organon-logo.svg';
export const BRAND_WHITE_LOGO = '/assets/images/logos/nori-white-logo.webp';
export const ALLY_ICON =
  '/assets/images/logos/essential-accessibility-icon.svg';
export const SEAL = '/assets/images/logos/seal.svg';
export const BRAND_LOGO = '/assets/images/logos/nexplanon-logo.webp';
export const GENERAL_JOB_CODE = 'US-XPL-117008 01/24';
export const REMOVE_UPSCRIPT_JOB_CODE = 'US-XPL-116928 10/23';
export const NAV_HOME_JOB_CODE = 'US-XPL-116748 09/23';
export const SPANISH_JOB_CODE = 'US-XPL-117198 08/24';

export const QUICK_LIST = {
  title: 'Enlaces Rápidos',
  links: [
    {
      label: 'Información de Prescripción',
      href: '/static/pdf/PI.pdf',
      target: '_blank',
      itemIcon: 'file',
      pdf: true,
    },
    {
      label: 'Información para el Paciente',
      href: '/static/pdf/PPI.pdf',
      target: '_blank',
      itemIcon: 'file',
      pdf: true,
    },
    {
      label: 'Profesional de la Salud',
      href: '',
      target: '_blank',
      itemIcon: 'arrow',
      modal: true,
    },
    {
      label: 'Sitio en Inglés',
      href: '/',
      target: '_blank',
      itemIcon: 'arrow',
    },
  ],
};

export const NAVIGATION_MOBILE_LIST = [];

export const NAVIGATION_SINGLE_ITEMS = [
  {
    label: 'Efectos Secundarios y Riesgos',
    href: '/es-us/efectos-secundarios/',
    target: '_self',
    itemIcon: 'arrow',
  },
  {
    label: 'Preguntas Frecuentes',
    href: '/es-us/preguntas/',
    target: '_self',
    itemIcon: 'arrow',
  },
];

export const FOOTER_CONTENT = {
  navigationVariant: 'alternative',
  showBrandFooter: false,
  altLogoOrganon: 'Logotipo de Organon',
  navList: {
    privacyLink: {
      children: 'Política de Privacidad',
      'data-design-label': 'Política de Privacidad',
    },
    termsLink: {
      children: 'Condiciones de Uso',
      'data-design-label': 'Condiciones de Uso',
    },
    cookiesButton: {
      children: 'Preferencias de Cookies',
    },
    siteMapLink: {
      children: 'Mapa del Sitio',
      'data-design-label': 'Mapa del Sitio',
    },
  },
  copyright:
    'Grupo de empresas Organon. Todos los derechos reservados. ORGANON y el logotipo de ORGANON son marcas comerciales del grupo de compañías Organon. Este sitio está destinado únicamente a residentes de los Estados Unidos, sus territorios y Puerto Rico.',
};

const LINK_INDEX = {
  prescribing: 0,
  sideEffects: 0,
  healthcare: 2,
  patientInfo: 1,
  questions: 1,
  lang: 3,
};

export const NAVIGATION_ALT_LIST = {
  title: null,
  links: [
    QUICK_LIST.links[LINK_INDEX.prescribing],
    NAVIGATION_SINGLE_ITEMS[LINK_INDEX.sideEffects],
    QUICK_LIST.links[LINK_INDEX.healthcare],
    QUICK_LIST.links[LINK_INDEX.patientInfo],
    NAVIGATION_SINGLE_ITEMS[LINK_INDEX.questions],
    QUICK_LIST.links[LINK_INDEX.lang],
  ],
};
