import React, { useEffect, useState, useRef } from 'react';
import { Montserrat } from 'next/font/google';
import { createPortal } from 'react-dom';
import styles from 'Nori/Modal/index.module.scss';
import CloseIcon from '@/public/icons/close.svg';
import useTrapFocus from 'Utils/hooks/useTrapFocus';
import useKeydownHandler from 'Utils/hooks/useKeyboardHandler';

const CLOSE_DELAY = 200;

const montserrat = Montserrat({
  weight: ['300', '400', '500', '600'],
  style: ['normal', 'italic'],
  subsets: ['latin'],
  fallback: ['sans-serif'],
  variable: '--font-montserrat',
});

const Modal = ({
  className = '',
  onClose = () => {},
  children,
  isOpen,
  ...props
}) => {
  const [mounted, setMounted] = useState(false);
  const modalRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  useEffect(() => {
    document.body.classList.toggle('overflowHidden', isOpen);

    if (isOpen) {
      containerRef.current.focus();
    }

    return () => {
      document.body.classList.remove('overflowHidden');
    };
  }, [isOpen]);

  const handleClose = () => {
    if (modalRef.current) {
      modalRef.current.classList.add(styles.closing);
    }

    setTimeout(() => onClose(false), CLOSE_DELAY);
  };

  useTrapFocus(containerRef, isOpen);
  useKeydownHandler(handleClose, 'Escape');

  const handleCloseOutside = (e) => {
    if (e.target === containerRef.current) {
      handleClose();
    }
  };

  return mounted && isOpen
    ? createPortal(
        <div
          ref={modalRef}
          className={`${montserrat.variable} ${styles.modal} ${className}`}
          {...props}
        >
          <div className={styles.modalBackdrop}></div>
          <div
            className={styles.modalContainer}
            ref={containerRef}
            tabIndex="0"
            onClick={handleCloseOutside}
          >
            <div className={`${styles.modalContent} modalContent`}>
              <div className={styles.modalBody}>{children}</div>
            </div>
          </div>
        </div>,
        document.body
      )
    : null;
};

export default Modal;
