import React from 'react';
import styles from 'Nori/Container/index.module.scss';

const CONTAINER_SIZES = {
  md: styles.medium,
  default: '',
};

const Container = ({ className = '', size = '', children, ...props }) => {
  const sizeClassName = CONTAINER_SIZES[size] || CONTAINER_SIZES.default;

  return (
    <div
      className={`${styles.container} ${sizeClassName} ${className}`}
      {...props}
    >
      {children}
    </div>
  );
};

export default Container;
