import React, { useRef, useEffect } from 'react';
import styles from './MobileMenu.module.scss';
import { useRouter } from 'next/router';
import { useAppContext } from 'Providers/App';
import Link from 'next/link';
import Carot from 'Images/brand-icons/angle-down.svg';
import Button from 'Nori/Button';
import useKeydownHandler from 'Utils/hooks/useKeyboardHandler';
import useTrapFocus from 'Utils/hooks/useTrapFocus';
import { MOBILE_MENU_CONTENT } from 'Custom/Header/Header.utils';
import { finderHandlerClick } from 'Custom/Finder/utils';
const ITEM_NOT_FOUND = '-1';

const HEADER_MOBILE_HEIGHT = 82;

const MenuItem = ({ href, label, id, submenu = null, target = null }) => {
  let icon = null;
  const { setMobileMenuOpen, setMobileMainMenu, setMobileActiveMenu } =
    useAppContext();

  const openSection = (id) => {
    setMobileMainMenu(true);
    setMobileActiveMenu(id);
  };

  if (target === '_blank') {
    icon = <ExternalLink />;
  }

  return href && !submenu ? (
    <Link
      className={styles.mobileItem}
      href={href}
      target={target}
      onClick={() => setMobileMenuOpen(false)}
    >
      <span
        data-design-type="clickAction"
        data-design-category="top_nav_vd"
        data-design-action="Link"
        data-design-label={label}
        data-design-nori_id="Navigation"
        data-design-module_name="Navigation"
        data-design-module_no="00"
      >
        {label}
      </span>
      {icon}
    </Link>
  ) : (
    <button onClick={() => openSection(id)} className={styles.mobileItem}>
      {label} {submenu && <Carot />}
    </button>
  );
};

const MobileMenu = ({ basic = false, content = MOBILE_MENU_CONTENT }) => {
  const { mainNavList, secondaryNavList } = content;
  const { pathname } = useRouter();
  const {
    handleModalClick,
    mobileMainMenu,
    setMobileMainMenu,
    mobileActiveMenu,
    setModalISI,
    setMobileMenuOpen,
    mobileMenuOpen,
  } = useAppContext();
  const wrapperRef = useRef(null);

  const resizeObserverFn = () => {
    const height = window.innerHeight - HEADER_MOBILE_HEIGHT;
    wrapperRef.current.style.height = `${height}px`;
  };

  useKeydownHandler(() => {
    setMobileMenuOpen(false);
    document.body.classList.toggle('overflowHidden', false);
  }, 'Escape');

  useTrapFocus(wrapperRef, mobileMenuOpen, [mobileMainMenu]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(resizeObserverFn);
    setTimeout(resizeObserverFn, 400);
    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.unobserve(document.body);
    };
  }, []);

  const menu = mainNavList.map((item, index) => {
    const { submenu = null } = item;
    const isActive =
      pathname === item.href ||
      (submenu && submenu.some((i) => i.href === pathname));

    const liClassName = `${isActive ? styles.active : ''}`;

    return (
      <li key={`li-${index}`} className={liClassName}>
        <MenuItem {...item} />
      </li>
    );
  });

  const subMenu = mainNavList.map((item, index) => {
    const { submenu = null, id } = item;

    if (mobileActiveMenu === id && submenu) {
      const isActive =
        pathname === item.link ||
        (submenu && submenu.some((i) => i.link === pathname));

      const liClassName = `${isActive ? styles.active : ''}`;

      return (
        <ul key={`ul-${index}`}>
          {submenu.map((subitem, subindex) => (
            <li
              key={`li-${index}-${subindex}`}
              className={
                subitem.href === pathname ? `${styles.activeSubItem}` : ''
              }
            >
              <MenuItem isSubItem {...subitem} />
            </li>
          ))}
        </ul>
      );
    }

    return null;
  });

  return (
    <div ref={wrapperRef} className={styles.mobileMenu} tabIndex="0">
      {mobileMainMenu ? (
        <div className={styles.subMenu}>
          <button
            className={styles.backButton}
            onClick={() => {
              setMobileMainMenu(false);
            }}
          >
            <Carot /> Back
          </button>
          <div className={styles.subNavWrapper}>
            <div className={styles.childItems}>
              {subMenu}
              <div className={styles.ctaBlockSub}>
                <Button
                  type="secondary2"
                  size="medium"
                  onClick={(e) => {
                    setMobileMenuOpen(false);
                    if (secondaryNavList.findDoctor['data-custom-redirect']) {
                      finderHandlerClick(e);
                    }
                  }}
                  data-design-type="clickAction"
                  data-design-category="top_nav_vd"
                  data-design-action="Button"
                  data-design-nori_id="Navigation"
                  data-design-module_name="Navigation"
                  data-design-module_no="00"
                  href="#find-a-doctor"
                  {...secondaryNavList.findDoctor}
                />

                <Button
                  onClick={() => setMobileMenuOpen(false)}
                  type="outlineSecondary2"
                  size="medium"
                  data-design-type="clickAction"
                  data-design-category="top_nav_vd"
                  data-design-action="Button"
                  data-design-label="My NEXPLANON"
                  data-design-nori_id="Navigation"
                  data-design-module_name="Navigation"
                  data-design-module_no="00"
                  href="/enrollment/"
                >
                  My NEXPLANON
                </Button>
              </div>
            </div>
            <div className={styles.subNavigation}>
              <div className={styles.subitemList}>
                <button
                  onClick={() => {
                    setMobileMenuOpen(false);
                    setModalISI(true);
                  }}
                  className={styles.topNavigation}
                  data-design-category="top_nav_vd"
                  data-design-type="clickAction"
                  data-design-action="Expand"
                  data-design-nori_id="Navigation"
                  data-design-module_name="Navigation"
                  data-design-module_no="00"
                  {...secondaryNavList.importantSafety}
                />
                <Link
                  onClick={() => setMobileMenuOpen(false)}
                  href="https://www.organon.com/product/usa/pi_circulars/n/nexplanon/nexplanon_pi.pdf"
                  target="_blank"
                  data-design-type="clickAction"
                  data-design-category="top_nav_vd"
                  data-design-action="Download"
                  data-design-nori_id="Navigation"
                  data-design-module_name="Navigation"
                  data-design-module_no="00"
                  {...secondaryNavList.prescribingInfo}
                />

                {!basic && (
                  <button
                    onClick={() => {
                      handleModalClick();
                      setMobileMenuOpen(false);
                    }}
                    className={styles.topNavigation}
                    data-design-type="clickAction"
                    data-design-category="top_nav_vd"
                    data-design-action="Expand"
                    data-design-nori_id="Navigation"
                    data-design-module_name="Navigation"
                    data-design-module_no="00"
                    {...secondaryNavList.healthcare}
                  />
                )}
                <Link
                  onClick={() => setMobileMenuOpen(false)}
                  href="https://www.organon.com/product/usa/pi_circulars/n/nexplanon/nexplanon_ppi.pdf"
                  target="_blank"
                  data-design-type="clickAction"
                  data-design-category="top_nav_vd"
                  data-design-action="Download"
                  data-design-nori_id="Navigation"
                  data-design-module_name="Navigation"
                  data-design-module_no="00"
                  {...secondaryNavList.patientInfo}
                />
                {!basic && (
                  <Link
                    onClick={() => setMobileMenuOpen(false)}
                    href="/es-us"
                    target="_blank"
                    data-design-type="clickAction"
                    data-design-category="top_nav_vd"
                    data-design-action="Link"
                    data-design-nori_id="Navigation"
                    data-design-module_name="Navigation"
                    data-design-module_no="00"
                    {...secondaryNavList.lang}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.mainSection}>
          {!basic && (
            <>
              <ul className={styles.mobileList}>{menu}</ul>
              <div className={styles.ctaBlock}>
                <Button
                  type="secondary2"
                  size="medium"
                  onClick={(e) => {
                    setMobileMenuOpen(false);
                    if (secondaryNavList.findDoctor['data-custom-redirect']) {
                      finderHandlerClick(e);
                    }
                  }}
                  data-design-type="clickAction"
                  data-design-category="top_nav_vd"
                  data-design-action="Button"
                  data-design-nori_id="Navigation"
                  data-design-module_name="Navigation"
                  data-design-module_no="00"
                  {...secondaryNavList.findDoctor}
                />

                <Button
                  onClick={() => setMobileMenuOpen(false)}
                  type="outlineSecondary2"
                  size="medium"
                  data-design-type="clickAction"
                  data-design-category="top_nav_vd"
                  data-design-action="Button"
                  data-design-label="My NEXPLANON"
                  data-design-nori_id="Navigation"
                  data-design-module_name="Navigation"
                  data-design-module_no="00"
                  href="/enrollment/"
                >
                  My NEXPLANON
                </Button>
              </div>
            </>
          )}
          <div className={styles.subitemList}>
            <button
              onClick={() => {
                setMobileMenuOpen(false);
                setModalISI(true);
              }}
              className={styles.topNavigation}
              data-design-category="top_nav_vd"
              data-design-type="clickAction"
              data-design-action="Expand"
              data-design-nori_id="Navigation"
              data-design-module_name="Navigation"
              data-design-module_no="00"
              {...secondaryNavList.importantSafety}
            />
            <Link
              onClick={() => setMobileMenuOpen(false)}
              href="https://www.organon.com/product/usa/pi_circulars/n/nexplanon/nexplanon_pi.pdf"
              target="_blank"
              data-design-type="clickAction"
              data-design-category="top_nav_vd"
              data-design-action="Download"
              data-design-nori_id="Navigation"
              data-design-module_name="Navigation"
              data-design-module_no="00"
              {...secondaryNavList.prescribingInfo}
            />

            {!basic && (
              <button
                onClick={() => {
                  handleModalClick();
                  setMobileMenuOpen(false);
                }}
                className={styles.topNavigation}
                data-design-type="clickAction"
                data-design-category="top_nav_vd"
                data-design-action="Expand"
                data-design-nori_id="Navigation"
                data-design-module_name="Navigation"
                data-design-module_no="00"
                {...secondaryNavList.healthcare}
              />
            )}
            <Link
              onClick={() => setMobileMenuOpen(false)}
              href="https://www.organon.com/product/usa/pi_circulars/n/nexplanon/nexplanon_ppi.pdf"
              target="_blank"
              data-design-type="clickAction"
              data-design-category="top_nav_vd"
              data-design-action="Download"
              data-design-nori_id="Navigation"
              data-design-module_name="Navigation"
              data-design-module_no="00"
              {...secondaryNavList.patientInfo}
            />

            {!basic && (
              <Link
                onClick={() => setMobileMenuOpen(false)}
                href="/es-us"
                target="_blank"
                data-design-type="clickAction"
                data-design-category="top_nav_vd"
                data-design-action="Link"
                data-design-nori_id="Navigation"
                data-design-module_name="Navigation"
                data-design-module_no="00"
                {...secondaryNavList.lang}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileMenu;
