import React from 'react';

const ENV = process.env.NEXT_PUBLIC_ENV;
const OT_KEY_PRODUCTION = process.env.NEXT_PUBLIC_OT_KEY_PRODUCTION;
const OT_KEY_STAGE = process.env.NEXT_PUBLIC_OT_KEY_STAGE;
const GTM_CODE = process.env.NEXT_PUBLIC_GTM_CODE;

const OT_KEY = ENV === 'production' ? OT_KEY_PRODUCTION : OT_KEY_STAGE;

const SCRIPTS = [
  // GTM
  GTM_CODE && (
    <script
      id="gtag-base"
      key="gtag-base"
      dangerouslySetInnerHTML={{
        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer', '${GTM_CODE}');`,
      }}
    />
  ),
  <script
    id="rxscript"
    key="rxscript"
    src="https://in.rxengage.app/rxdefine.js/rxdefine.js?id=e49d049f-58f1-4684-a7ff-1d15edd68322"
  />,

  <script
    id="rxscript-custom"
    key="rxscript-custom"
    dangerouslySetInnerHTML={{
      __html: `function openChat() {
        window.RxDefineChat?.toggleOpen(true);
        window.RxDefineChat?.hideChat(false);
      }
      function closeChat() {
        window.RxDefineChat?.hideChat(true);
        window.RxDefineChat?.toggleOpen(false);
      }
      document.addEventListener('rxDefineChatReady', () => {
        window.RxDefineChat.setClosingMode('hide');
        window.RxDefineChat.onClose(closeChat);
      });`,
    }}
  />,
  // OT CCPA scripts
  OT_KEY && (
    <script
      id="ot-cookies"
      key="ot-cookies"
      src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      type="text/javascript"
      charSet="UTF-8"
      data-domain-script={OT_KEY} //add the right one for each domain
    />
  ),
  OT_KEY && (
    <script
      id="ot-callback"
      key="ot-callback"
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `function OptanonWrapper() {
        const onetrustModal = document.querySelector('#onetrust-consent-sdk');
        const onetrustConfirm = document.querySelector('#onetrust-accept-btn-handler');
        
        const resetBody = () => {
            document.body.style.removeProperty('top');
            document.body.style.removeProperty('position');
            document.body.style.removeProperty('overflow-y');
        }

        onetrustModal.addEventListener('click', (e) => {
            const isClosing = e.target.classList.contains('onetrust-close-btn-handler') || e.target.id === "accept-recommended-btn-handler";
            if(isClosing) {
            setTimeout(resetBody, 50);
            }
        });

        if(!onetrustConfirm) {
            return;
        }

        onetrustConfirm.addEventListener('click', (e) => {
            setTimeout(resetBody, 50);
        });
      }`,
      }}
    />
  ),
];

export default SCRIPTS;
