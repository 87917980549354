export const ARIA = {
  mainNavigation: 'Main navigation',
  openMainNavigation: 'Open main navigation',
  logo: 'NEXPLANON® (etonogestrel implant) 68 mg Radiopaque Logo',
};

export const Links = [
  {
    link: null,
    label: 'How It Works',
    id: 1,
    submenu: [
      {
        label: 'What Is NEXPLANON',
        href: '/what-is-nexplanon',
        target: '_self',
      },
      {
        label: 'How NEXPLANON Works',
        href: '/how-does-nexplanon-work',
        target: '_self',
      },
      {
        label: 'Understanding Birth Control',
        href: '/birth-control-options',
        target: '_self',
      },
      {
        label: 'Insertion & Aftercare',
        href: '/insertion',
        target: '_self',
      },
      {
        label: 'Removal & Aftercare',
        href: '/removal',
        target: '_self',
      },
      {
        label: 'Replacing Your Implant',
        href: '/replacing-implant',
        target: '_self',
      },
      {
        label: 'Getting Pregnant After NEXPLANON',
        href: '/pregnancy-after-nexplanon',
        target: '_self',
      },
      {
        label: 'Five Fast Facts',
        href: '/five-fast-facts',
        target: '_self',
      },
    ],
  },
  {
    link: null,
    label: 'Safety & Efficacy',
    id: 2,
    submenu: [
      {
        label: 'How Effective Is NEXPLANON',
        href: '/effectiveness',
        target: '_self',
      },
      { label: 'Side Effects & Risks', href: '/side-effects', target: '_self' },
      {
        label: 'Changes to Your Period',
        href: '/period-changes',
        target: '_self',
      },

      {
        label: 'When to Call Your Doctor',
        href: '/calling-your-doctor',
        target: '_self',
      },
      {
        label: 'Medicine Interactions',
        href: '/medicine-interactions',
        target: '_self',
      },
    ],
  },
  {
    link: null,
    label: 'Cost & Coverage',
    id: 3,
    submenu: [
      { label: 'Cost', href: '/cost', target: '_self' },
      {
        label: 'Insurance Coverage',
        href: '/insurance-coverage',
        target: '_self',
      },
    ],
  },
  {
    href: '/questions',
    label: 'FAQ',
    id: 4,
  },
];

export const STICKY_CONTENT = {
  navList: {
    variant: 'main',
    findDoctor: {
      children: 'Find a Doctor',
      'data-design-label': 'Find a Doctor',
      href: '#find-a-doctor',
      'data-custom-redirect': true,
    },
    prescribingInfo: {
      children: 'Prescribing Information',
      href: 'https://www.organon.com/product/usa/pi_circulars/n/nexplanon/nexplanon_pi.pdf',
      'data-design-label': 'Prescribing Information',
    },
    patientInfo: {
      children: 'Patient Information',
      href: 'https://www.organon.com/product/usa/pi_circulars/n/nexplanon/nexplanon_ppi.pdf',
      'data-design-label': 'Patient Information',
    },
    importantSafety: {
      children: 'Important Safety Information',
      'data-design-label': 'Important Safety Information',
    },
    healthcare: {
      children: 'Healthcare Professionals',
      'data-design-label': 'Healthcare Professionals',
    },
    lang: {
      children: 'Español',
      'data-design-label': 'Español',
    },
  },
};

export const MOBILE_MENU_CONTENT = {
  mainNavList: Links,
  secondaryNavList: STICKY_CONTENT.navList,
};

export const HEADER_CONTENT = {
  navigationVariant: null,
  mainRoute: '/',
  mobileContent: MOBILE_MENU_CONTENT,
  ARIA,
};
