export const HEALTHCARE_MODAL_CONTENT = {
  ARIA: {
    closeModal: 'Close Modal',
  },
  title: 'Are you a healthcare professional?',
  children: (
    <p>
      This section contains information intended for healthcare professionals in
      the United States only and is not intended for the general public.
    </p>
  ),
  returnButton: {
    children: 'No, I am not. Please take me back.',
    'data-design-label': 'No, I am not. Please take me back.',
  },
  confirmButton: {
    href: 'https://organonpro.com/en-us/product/nexplanon/overview/',
    children: 'Yes, I am.',
    'data-design-label': 'Yes, I am.',
  },
  bottomContent: (
    <>
      <p>
        This site is intended only for residents of the United States, its
        territories, and Puerto Rico.
      </p>
      <p>
        {`© ${new Date().getFullYear()}`} Organon group of companies. All rights
        reserved.
      </p>
    </>
  ),
};
