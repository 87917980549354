import React from 'react';
import styles from 'Custom/FooterDropdown/FooterHeader.module.scss';
import AngleDown from 'Images/brand-icons/angle-down.svg';
import Plus from 'Images/brand-icons/plus.svg';
import Minus from 'Images/brand-icons/minus.svg';

const FooterHeader = ({ isExpanded, children, ...props }) => {
  return (
    <div className={`${styles.header}`} {...props}>
      <button className={styles.accordionButton} aria-expanded={isExpanded}>
        <span className={styles.faqItemTitle}>{children}</span>
        {isExpanded ? <Minus /> : <Plus />}
      </button>
    </div>
  );
};

export default FooterHeader;
