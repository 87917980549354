import React from 'react';

export const LOGO = '/assets/images/logos/organon-logo.svg';
export const BRAND_WHITE_LOGO = '/assets/images/logos/nori-white-logo.webp';
export const ALLY_ICON =
  '/assets/images/logos/essential-accessibility-icon.svg';
export const SEAL = '/assets/images/logos/seal.svg';
export const BRAND_LOGO = '/assets/images/logos/nexplanon-logo.webp';
export const GENERAL_JOB_CODE = 'US-XPL-117008 01/24';
export const TERMS_JOB_CODE = 'US-XPL-117113 03/24';
export const REMOVE_UPSCRIPT_JOB_CODE = 'US-XPL-116928 10/23';
export const NAV_HOME_JOB_CODE = 'US-XPL-116748 09/23';
export const NAV_UPDATES_JOB_CODE = 'US-XPL-117336 08/24';

export const NAV_ITEMS_1 = {
  title: 'How It Works',
  links: [
    {
      label: 'What Is NEXPLANON',
      href: '/what-is-nexplanon',
      target: '_self',
    },
    {
      label: 'How NEXPLANON Works',
      href: '/how-does-nexplanon-work',
      target: '_self',
    },
    {
      label: 'Understanding Birth Control',
      href: '/birth-control-options',
      target: '_self',
    },
    {
      label: 'Insertion & Aftercare',
      href: '/insertion',
      target: '_self',
    },
    {
      label: 'Removal & Aftercare',
      href: '/removal',
      target: '_self',
    },
    {
      label: 'Replacing Your Implant',
      href: '/replacing-implant',
      target: '_self',
    },
    {
      label: 'Getting Pregnant After NEXPLANON',
      href: '/pregnancy-after-nexplanon',
      target: '_self',
    },
    {
      label: 'Five Fast Facts',
      href: '/five-fast-facts',
      target: '_self',
    },
  ],
};

export const NAV_ITEMS_2 = {
  title: 'Safety & Efficacy',
  links: [
    {
      label: 'How Effective Is NEXPLANON',
      href: '/effectiveness',
      target: '_self',
    },
    { label: 'Side Effects & Risks', href: '/side-effects', target: '_self' },
    {
      label: 'Changes to Your Period',
      href: '/period-changes',
      target: '_self',
    },
    {
      label: 'When to Call Your Doctor',
      href: '/calling-your-doctor',
      target: '_self',
    },
    {
      label: 'Medicine Interactions',
      href: '/medicine-interactions',
      target: '_self',
    },
  ],
};

export const NAV_ITEMS_3 = {
  title: 'Cost & Coverage',
  links: [
    { label: 'Cost', href: '/cost', target: '_self' },
    {
      label: 'Insurance Coverage',
      href: '/insurance-coverage',
      target: '_self',
    },
  ],
};

export const NAV_ITEMS_4 = {
  title: 'FAQ',
  links: [{ label: 'FAQ Page', href: '/questions', target: '_self' }],
};

export const NAV_ITEMS_5 = {
  title: 'Quick Links',
  links: [
    {
      label: 'Prescribing Information',
      href: 'https://www.organon.com/product/usa/pi_circulars/n/nexplanon/nexplanon_pi.pdf',
      target: '_blank',
      icon: true,
      pdf: true,
    },
    {
      label: 'Patient Information',
      href: 'https://www.organon.com/product/usa/pi_circulars/n/nexplanon/nexplanon_ppi.pdf',
      target: '_blank',
      icon: true,
      pdf: true,
    },
    {
      label: 'Healthcare Professionals',
      href: '',
      target: '_blank',
      icon: true,
      modal: true,
    },
    { label: 'Español', href: '/es-us', icon: true, target: '_blank' },
  ],
};

export const NAVIGATION_MOBILE_LIST = [NAV_ITEMS_1, NAV_ITEMS_2, NAV_ITEMS_3];

export const NAVIGATION_SINGLE_ITEMS = [
  { label: 'FAQ', href: '/questions', target: '_self', icon: true },
];

export const NAVIGATION_LIST = [
  { text: 'Footer Item', href: '/' },
  { text: 'Footer Item', href: '/' },
  { text: 'Footer Item', href: '/' },
  { text: 'Footer Item', href: '/' },
  { text: 'Footer Item', href: '/' },
];

export const FOOTER_CONTENT = {
  navigationVariant: null,
  showBrandFooter: true,
  altLogoOrganon: 'Organon Logo',
  navList: {
    privacyLink: {
      children: 'Privacy Policy',
      'data-design-label': 'Privacy Policy',
    },
    termsLink: {
      children: 'Terms of Use',
      'data-design-label': 'Terms of Use',
    },
    cookiesButton: {
      children: 'Cookie Preferences',
    },
    siteMapLink: {
      children: 'Site Map',
      'data-design-label': 'Site Map',
    },
  },
  copyright:
    'Organon group of companies. All rights reserved. ORGANON and the ORGANON Logo are trademarks of the Organon group of companies. This site is intended only for residents of the United States, its territories, and Puerto Rico.',
};
